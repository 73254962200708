<template>
  <section class="live">
    <a-spin :spinning="spinning">
      <div class="content">
        <div class="cover">
          <img v-if="dataInfo.cover" :src="dataInfo.cover" alt="cover" />
          <img v-else src="../../assets/image/live-cover.png" alt="cover" />
          <p class="source" v-if="dataInfo.taskType == 1">
            {{ $t('LB_Doc_Source') }}：{{ dataInfo.taskName }}
            <!-- 来源： -->
          </p>
        </div>
        <div class="text">
          <h3>{{ dataInfo.roomName }}</h3>
          <p class="time">
            <!-- 直播时间： -->
            {{ $t('Lab_Evaluate_T_WebCastTime') }}：{{ dateFormat(dataInfo.startTime) }} ~
            {{ dateFormat(dataInfo.endTime) }}
          </p>
          <div class="status">
            <span>
              <template v-if="dataInfo.complete == 1">{{ $t('CM_Completed') }}</template>
              <!-- 已完成 -->
              <template v-else-if="dataInfo.complete == 3">{{ $t('cm_overdue') }}</template>
              <!-- 已逾期 -->
              <template v-else-if="dataInfo.complete == 2">{{ $t('CM_NotCompleted') }}</template>
              <!-- 未完成 -->
              <template v-else>{{ $t('CM_NoTakePartIn') }}</template>
              <!-- 未参与 -->
            </span>
            <p>
              {{ $t('live.watch_time') }}：<i>{{ formatTime(dataInfo.stayDuration || 0) }}</i>
              <!-- 已看时长： -->
            </p>
            <p style="margin-left: 36px">
              {{ $t('live.required_study_time') }}：<i>{{ dataInfo.learnTime }} {{ $t('CM_Minute') }}</i>
              <!-- 要求学习时长： -->
            </p>
          </div>
          <div class="set-status">
            <p
              v-if="useLearnTime && dataInfo.studyIntegral"
            >
              {{ $t('LB_Pro_Integral') }}：<i>{{ dataInfo.studyIntegral }}</i>
              <!-- 积分： -->
            </p>
            <p
              style="margin-left: 36px"
              v-if="useLearnScore && dataInfo.studyScore"
            >
              {{ $t('Pub_Tab_Credit') }}：<i>{{ dataInfo.studyScore }}</i>
              <!-- 学分： -->
            </p>
            <p
              style="margin-left: 36px"
              v-if="hasIntegralMenu && dataInfo.studyTime"
            >
              {{ $t('CM_Period') }}：<i>{{ dataInfo.studyTime }}</i>
              <!-- 学时： -->
            </p>
          </div>
          <div class="bottom">
            <template v-if="dataInfo.liveType == 2">
              <a-button
                disabled
                class="btn"
                v-if="currentTime < dataInfo.startTime"
                >{{ $t('live.live_not_started') }}</a-button
              >
              <!-- 直播未开始 -->
              <a-button
                type="primary"
                class="btn"
                :loading="loading"
                v-else-if="
                  currentTime > dataInfo.endTime && dataInfo.openReplay
                "
                @click="viewReplay(dataInfo.roomId)"
                >{{ $t('live.view_replay') }}</a-button
              >
              <!-- 查看回放 -->
              <a-button
                disabled
                class="btn"
                v-else-if="currentTime > dataInfo.endTime"
                >{{ $t('live.live_has_ended') }}</a-button
              >
              <!-- 直播已结束 -->
              <a-button
                type="primary"
                class="btn"
                :loading="loading"
                v-else
                @click="goWxLive(dataInfo.roomId)"
                >{{ $t('LB_Live_EnterLive') }}</a-button
              >
              <!-- 进入直播 -->
            </template>
            <template v-else-if="dataInfo.liveType == 3">
              <a-button
                disabled
                class="btn"
                v-if="currentTime < dataInfo.startTime"
                >{{ $t('live.live_not_started') }}</a-button
              >
              <!-- 直播未开始 -->
              <a-button
                type="primary"
                class="btn"
                v-else
                @click="goDdLive(dataInfo.roomId)"
                >{{
                  dataInfo.dd_live_status == 3 ? $t('live.view_replay') : $t('LB_Live_EnterLive')
                }}</a-button
              >
              <!-- "查看回放" : "进入直播" -->
            </template>
            <template v-else>
              <a-button
                disabled
                class="btn"
                v-if="currentTime < dataInfo.startTime"
                >{{ $t('live.live_not_started') }}</a-button
              >
              <!-- 直播未开始 -->
              <a-button
                disabled
                class="btn"
                v-else-if="currentTime > dataInfo.endTime"
                >{{ $t('live.live_has_ended') }}</a-button
              >
              <!-- 直播已结束 -->
              <a-button
                type="primary"
                class="btn"
                v-else
                @click="open(dataInfo.watchUrl)"
                >{{ $t('LB_Live_EnterLive') }}</a-button
              >
              <!-- 进入直播 -->
            </template>
            <div class="persons">
              <div class="person-item">
                <div class="avatar">
                  <img
                    :src="dataInfo.lecturerAvatar"
                    alt="avatar"
                    v-if="dataInfo.lecturerAvatar"
                  />
                  <a-avatar :size="40" v-else>
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </div>
                <div>
                  <div class="name" v-if="dataInfo.lecturerUid">
                    <OpenData
                      type="userName"
                      :openid="dataInfo.lecturerName"
                      v-if="dataInfo.lecturerType == 1"
                    /><span v-else>{{ dataInfo.lecturerName }}</span>
                  </div>
                  <div class="name" v-else>{{ dataInfo.lecturerName }}</div>
                  <div class="label">{{ $t('live.speaker') }}</div>
                  <!-- 主讲 -->
                </div>
              </div>
              <div class="person-item">
                <div class="avatar">
                  <img :src="dataInfo.createAvatar" alt="avatar" />
                </div>
                <div>
                  <div class="name">
                    <OpenData type="userName" :openid="dataInfo.createUser" />
                  </div>
                  <div class="label">{{ $t('cm_creator') }}</div>
                  <!-- 创建人 -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </section>

  <section class="intro">
    <div class="content">
      <div class="component-tit">{{ $t('live.live_detail') }}</div>
      <!-- 直播详情 -->
      <a-empty v-if="!dataInfo.intro" style="padding: 50px 0" />
      <div v-else v-html="dataInfo.intro"></div>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { liveDetail, getWxLiveStatus, getDdLiveStatus } from "@/api/live";
import OpenData from "@/components/OpenData.vue";
import { dateFormat, formatTime } from "@/utils/tools";
import wxwork from "@/utils/wxwork";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const taskId = parseInt(route.query.id || 0);
    const detailId = parseInt(route.query.did || 0);

    const useLearnTime = ref(false);
    const useLearnScore = ref(false);
    const hasIntegralMenu = ref(false);

    watch(
      () => store.getters.companyInfo,
      (val) => {
        if (val.useLearnTime == 1) useLearnTime.value = true;
        if (val.useLearnScore == 1) useLearnScore.value = true;
        if (val.menu) {
          if (val.menu.includes("integral")) hasIntegralMenu.value = true;
        }
      },
      { immediate: true, deep: true }
    );

    const currentTime = Date.parse(new Date()) / 1000;
    const spinning = ref(true);
    const dataInfo = ref({});
    const isWxWork = ref(false);
    const getDetail = async () => {
      const res = await liveDetail({ taskId, detailId });
      if (res.ret !== 0) {
        if (res.msg.indexOf("不存在") > -1) {
          router.push("/mine#live");
        }
        return false;
      }
      dataInfo.value = res.data;
      if (dataInfo.value.liveType == 3) {
        dataInfo.value.dd_live_status = 0; // 0：预告态 1：直播态 3：结束态
        getDdLiveStatus({
          taskId,
          detailId,
        }).then((res) => {
          dataInfo.value.dd_live_status = res.data;
        });
      }
      // 企业微信直播
      const ua =
        navigator && navigator.userAgent
          ? navigator.userAgent.toLowerCase() || ""
          : "";
      isWxWork.value = /wxwork/i.test(ua); // 是否企业微信
      if (isWxWork.value && dataInfo.value.liveType == 2) {
        wxwork.agentConfig();
      }
      /**
       * 企业微信讲师直播 -- 没有一个学员参与 导致openReplay字段未更新 学员查看不了回放
       * 直播结束后&&未参与&&openReplay==0 调一次更新openReplay状态接口
       */
      const flag =
        dataInfo.value.liveType == 2 &&
        currentTime > res.data.endTime &&
        !res.data.complete &&
        !res.data.openReplay;
      if (flag) {
        await getWxLiveStatus({ taskId, detailId });
        const result = await liveDetail({ taskId, detailId });
        if (result.ret === 0) {
          dataInfo.value.openReplay = result.data.openReplay;
        }
      }
      spinning.value = false;
    };
    getDetail();

    const open = () => {
      let urlStr = `./room?id=${taskId}&did=${detailId}`;
      if (store.getters.companyInfo.useNewWindow == 1) {
        window.open(urlStr + "&ddtab=true");
      } else {
        window.location.href = urlStr;
      }
    };

    const loading = ref(false);
    const goWxLive = (roomId) => {
      if (isWxWork.value) {
        loading.value = true;
        getWxLiveStatus({
          taskId,
          detailId,
        }).then((res) => {
          if (res.ret == 0) {
          }
          loading.value = false;
        });
        wxwork.startLiving(roomId);
      } else {
        proxy.$message.info($t('live.wxwork_tip3'));
        // 请用企业微信参与直播
      }
    };

    const goDdLive = (roomId) => {
      window.open(
        `dingtalk://dingtalkclient/action/start_uniform_live?liveUuid=${roomId}`
      );
    };

    const viewReplay = (roomId) => {
      if (isWxWork.value) {
        loading.value = true;
        wxwork.replayLiving(roomId);
        loading.value = false;
      } else {
        proxy.$message.info($t('live.wxwork_tip2'));
        // 请用企业微信查看直播
      }
    };

    onMounted(() => {});

    return {
      useLearnTime,
      useLearnScore,
      hasIntegralMenu,
      dateFormat,
      formatTime,
      currentTime,
      spinning,
      dataInfo,
      open,
      goWxLive,
      goDdLive,
      viewReplay,
      loading,
    };
  },
};
</script>

<style lang="less" scoped>
.live {
  padding-top: 30px;
  .content {
    .mixinWrap();
    background-color: #fff;
    padding: 50px 40px;
    .mixinFlex(space-between; center);
    .cover {
      .mixinImgWrap(399px; 100%);
      border-radius: 6px;
      position: relative;
      .source {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: #fff;
        font-size: 14px;
        .mixinEllipsis(36px);
        padding: 0 12px;
        background-color: rgba(0, 0, 0, 0.5);
        margin-bottom: 0;
      }
    }
    .text {
      width: calc(100% - 429px);
      h3 {
        font-size: 36px;
        margin-bottom: 0;
        .mixinEllipsis(54px);
      }
      .time {
        font-size: 20px;
        color: #999;
        line-height: 30px;
        margin-bottom: 20px;
      }
      .status {
        .mixinFlex(flex-start; center);
        line-height: 22px;
        font-size: 14px;
        span {
          color: @color-theme;
          line-height: 20px;
          height: 22px;
          border: 1px solid @color-theme;
          border-radius: 10px;
          padding: 0 5px;
        }
        p {
          margin: 0 0 0 10px;
          color: #999;
          i {
            color: #666;
            font-style: normal;
          }
        }
      }
      .set-status {
        .mixinFlex(flex-start; center);
        margin-top: 20px
      }
      .bottom {
        .mixinFlex(space-between; center);
        margin-top: 42px;
        .btn {
          width: 200px;
          height: 54px;
          font-size: 20px;
          border-radius: 4px;
        }
        .persons {
          .mixinFlex(flex-start; center);
          .person-item {
            .mixinFlex(space-between; center);
            margin-left: 10px;
            .avatar {
              .mixinImgWrap(40px; 40px);
              border-radius: 50%;
              margin-right: 12px;
            }
            .name {
              font-size: 18px;
            }
            .label {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
  }
}

.intro {
  padding-top: 30px;
  .content {
    .mixinWrap();
    background-color: #fff;
    padding: 30px 40px;
    .component-tit {
      font-size: 18px;
      line-height: 18px;
      color: #333;
      font-weight: bold;
      padding-left: 6px;
      margin: 4px 0 20px;
      border-left: 6px solid @color-theme;
    }
  }
}
</style>
